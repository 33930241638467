import React, { useEffect, useState } from 'react';
import "./PortfolioPage.css";
import e2eProject1 from '../assets/images/projects/e2e-project1.png';
import e2eProject2 from '../assets/images/projects/e2e-project2.png';
import e2eProject3 from '../assets/images/projects/e2e-project3.png';
import resume from '../assets/resume/Jeffrey Bradley - Resume.pdf';
import avatar from '../assets/images/my-avatar.png';
import githubLogo from '../assets/images/icon-logo-github.png';
import linkedinLogo from '../assets/images/icon-logo-linkedin.png';
import mail from '../assets/images/icon-email-50.png';
import { Document, Page, pdfjs } from 'react-pdf';
import { Tabs, Tab, Card, CardBody, CardHeader } from "@nextui-org/react";
import "pdfjs-dist/build/pdf.worker.mjs";


const PortFolioTopImage = () => {
    return (
        <div>
            <img id="my-avatar" src={avatar} alt="Jeff as a Cartoon" />
        </div>
    );
};


const Contacts = ({ img, type, contactInfo }) => {
    return (
        <section className="contact-info">
            <a href={type === "Email" ? `mailto:${contactInfo}` : null}>
                <img src={img} alt={type} />
            </a>
            <div className="contact-info-text">
                <h4>{type}</h4>
                <p>{contactInfo}</p>
            </div>
        </section>
    )
}

const PortfolioHeader = () => {
    return (
        <section id="portfolio-page-header">
            <PortFolioTopImage />
            <h3>
                Jeffrey Bradley
            </h3>
            <ul>
                <li>
                    <a href="https://github.com/JeffreyBradley772">
                        <img src={githubLogo} alt="My Github Profile" />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/jeffreybradley772/">
                        <img src={linkedinLogo} alt="My LinkedIn Profile" />
                    </a>
                </li>
                <hr />
            </ul>

            <Contacts img={mail} type="Email" contactInfo="JeffreyBradley772@gmail.com" />
        </section>
    );
};



// PROJECTS

const ProjectsContainer = () => {

    const [selectedOption, setSelectedOption] = useState('Resume');

    return (
        <section id="projects-wrapper">
            <CornerOptions selectedOption={selectedOption} setSelectedOption={setSelectedOption} />

            <div className="content">
                {selectedOption === 'Experience' && <ExperienceContent />}
                {selectedOption === 'Portfolio' && <PortfolioContent />}
                {selectedOption === 'About Me' && <AboutContent />}
                {selectedOption === 'Resume' && <ResumeContent />}
            </div>
        </section>
    )
};

const CornerOptions = ({ selectedOption, setSelectedOption }) => {
    return (
        <section id="corner-options">
            <ul>
                <li className={selectedOption === 'About Me' ? 'active' : ''} onClick={() => { setSelectedOption('About Me') }}>
                    <button>About me</button>
                </li>
                <li className={selectedOption === 'Experience' ? 'active' : ''} onClick={() => { setSelectedOption('Experience') }}>
                    <button>Experience</button>
                </li>
                <li className={selectedOption === 'Resume' ? 'active' : ''} onClick={() => { setSelectedOption('Resume') }}>
                    <button>Resume</button>
                </li>
                <li className={selectedOption === 'Portfolio' ? 'active' : ''} onClick={() => { setSelectedOption('Portfolio') }}>
                    <button>Portfolio</button>
                </li>
                <li className={selectedOption === 'Home' ? 'active' : ''} onClick={() => { window.location.href = "/"; }}>
                    <button>Home</button>
                </li>
            </ul>
        </section>
    );
}


const Job = ({ jobTitle, jobDuration, skills }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleSkills = () => {
        setIsExpanded(!isExpanded);
    };

    const displayedSkills = isExpanded ? skills : skills.slice(0, 2);
    const showToggleButton = skills.length > 2;

    return (
        <div className="job-container">
            <h4 className="job-title">{jobTitle}</h4>
            <p className="job-duration">{jobDuration}</p>

            <ul className="skills-list custom-bullets">
                {displayedSkills.map((skill, index) => (
                    <li key={index}>{skill}</li>
                ))}
            </ul>

            {showToggleButton && (
                <button onClick={toggleSkills} className="toggle-skills-button">
                    {isExpanded ? 'Less' : 'More'}
                </button>
            )}
        </div>
    );
};


const ExperienceContent = () => {
    return (
        <div id="experience-section">
            <h4 className="section-title">Experience</h4>

            <div className="company-container" id="higher-ground">
                <h3 className="company-name">Higher Ground LLC</h3>
                <p className="location">Palo Alto, California, United States</p>

                <Job
                    jobTitle="Senior Software Engineer"
                    jobDuration="Jul 2024 - Present"
                    skills={[
                        'Software Infrastructure',
                        'Full-Stack Development',
                        'Backend Web Development',
                        'Docker',
                        'Jenkins',
                        'Django REST Framework',
                        'Kotlin',
                        'NestJS',
                        'REST APIs',
                        'Azure Virtual Machines',
                        'Azure SQL',
                        'Redis',
                        'Vite',
                        'React',
                    ]}
                />

                <Job
                    jobTitle="Member Of Technical Staff"
                    jobDuration="Jun 2021 - Jul 2024"
                    skills={[
                        'Application Programming Interfaces (API)',
                        'Embedded Software Programming',
                        'Android Development',
                        'REST APIs',
                    ]}
                />
            </div>

            <div className="company-container" id="attochron">
                <h3 className="company-name">Attochron LLC</h3>
                <p className="location">Lexington, Virginia</p>

                <Job
                    jobTitle="Electrical and Instrumentation Engineer"
                    jobDuration="Jan 2020 - Jul 2021"
                    skills={[
                        'Application Programming Interfaces (API)',
                        'Embedded Software Programming',
                        'Hardware-Software Integration',
                        'Data Processing and Visualization',
                        'Web Development',
                    ]}
                />
            </div>

            <div className="company-container" id="washington-lee">
                <h3 className="company-name">Washington and Lee University</h3>
                <p className="location">Lexington, Virginia</p>

                <Job
                    jobTitle="Data Science Research Associate"
                    jobDuration="Sep 2020 - Jun 2021"
                    skills={[
                        'Machine learning',
                        'Deep learning for image processing',
                        'Python',
                        'AWS',
                        'Scikit-Learn',
                        'Pandas',
                        'NumPy',
                        'Image Manipulation',
                        'Jupyter Notebook',
                    ]}
                />

                <Job
                    jobTitle="Teaching Assistant - Electrical Circuits Lab"
                    jobDuration="Sep 2019 - Jan 2020"
                    skills={[
                        'Circuit design, construction, and testing',
                        'MCU Programming in C',
                        'MatLab',
                    ]}
                />
            </div>
        </div>
    );
};


const PortfolioContent = () => {
    const projects = ["Android", "Web", "APIs/Middleware", "Infrastructure"]

    const [selectedProject, setSelectedProject] = useState(projects[0])

    return (
        <div>
            <h4>Portfolio</h4>
            <ProjectOptions projects={projects} selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
            {selectedProject === "Android" && < AndroidProjects />}
            {selectedProject === "Web" && < WebProjects />}
            {selectedProject === "APIs/Middleware" && < APIProjects />}
        </div>
    );
}

const AboutContent = () => {
    return (
        <div>
            <h4>About me</h4>
            <br />
            <p style={{ fontSize: '1.5rem', textIndent: '25px', lineHeight: '2rem', fontWeight: 'bold' }}>
                I've had the privilege of working at startups for most of my career, which has taught me to be comfortable wearing many hats. I thrive in environments where no two days are the same and I'm constantly learning new technologies. I'm always on the lookout for the next tool or technique that will help me work more efficiently and effectively.
            </p>
            <br />
            <p style={{ fontSize: '1.5rem', textIndent: '25px', lineHeight: '2rem', fontWeight: 'bold' }}>
                My passion is building systems that optimize the experience for engineers, developers, and customers alike. Whether it's streamlining a development workflow, building a scalable infrastructure, or creating a user-friendly interface, I love finding ways to make complex systems simple and easy to use.
            </p>
        </div>
    );
}

const PdfViewer = ({ pdfUrl }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1); // Reset to first page
    };

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    return (
        <div>
            <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            <div>
            </div>
        </div>
    );
};

const ResumeContent = () => {

    return (
        <div>
            <a href="Jeffrey Bradley - Resume.pdf" download>
                <button className='btn' style={{ paddingBottom: '10px', marginBottom: '10px' }}>
                    Download PDF
                </button>
            </a>
            <PdfViewer pdfUrl={resume} />
        </div>

    );
}

const ProjectOptions = ({ projects, selectedProject, setSelectedProject }) => {
    return (
        <ul>
            {projects.map((project, index) => (
                <li className='project-option' key={index}>
                    <nav
                        onClick={() => setSelectedProject(project)}
                        className={`${selectedProject === project ? 'selected' : ''}`}
                    >
                        {project}
                    </nav>
                </li>
            ))}
        </ul >
    )
}

const E2eProject = () => {
    const elementsList = ["UI/UX", "System"]
    const [selectedElement, setSelectedElement] = useState(elementsList[0]);

    const handleElementChange = (element) => {
        setSelectedElement(element);
    };

    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>End-to-End Encryption in Satellite Messaging</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Technical lead in full stack development of end-to-end encryption over satellite messaging.</li>
                    <li className="text-2xl">Leveraged the FIPS 140-3 Bouncy Castle library to incorporate hybrid encryption and authentication.</li>
                    <li className="text-2xl">Implemented a secure key exchange protocol to ensure secure communication between servers and end device.</li>
                    <li className="text-2xl">Lead small team of engineers who assisted in android and backend development.</li>
                    <li className="text-2xl">Worked directly with the final customer on design choices to ensure the best final user experience.</li>
                </ul>
            </div>

            <div className="flex-container">
                {elementsList.map((element, index) => (
                    <button
                        key={index}
                        className={`button ${selectedElement === element ? 'active' : ''}`}
                        onClick={() => handleElementChange(element)}
                    >
                        {element}
                    </button>
                ))}
            </div>

            {selectedElement === "UI/UX" && (
                <>
                    <p className="text-2xl">The UI/UX below is simple because its SUPPOSED to be! The complex and clever parts happen all in the background. Having control of every technology in the design from the public keys database, the APIs between our servers and the satellite base station, and the user interface, was crucial in my ability to make this a fantastic and secure user experience. We recently demoed and and delivered the final product to a happy customer and are moving forward with a rebranding of the implementation.</p>

                    <div className='grid grid-cols-[1fr_1fr] items-center justify-center gap-5 py-20'>
                        <img className='h-[50rem] justify-self-center' src={e2eProject1} />
                        <img className='h-[50rem] justify-self-center' src={e2eProject2} />
                    </div>
                </>
            )}

            {selectedElement === "System" && (
                <>
                    <p className="text-2xl">
                        Here is a basic overview of the system design. For user-to-user messaging, messages are transferred via hybrid key exchange. Every message is verified to be using the correct public key.

                        For group messaging, the group owner generates and shares a symmetric via the original encrypted message design that each group member uses to encrypt and decrypt messages.
                    </p>

                    <div className='grid grid-cols-[1fr] gap-4 items-center justify-center gap-20 py-20'>
                        <img className='h-[50rem] justify-self-center' src={e2eProject3} />
                    </div>
                </>
            )}

        </div>
    );
}

const ImagesProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>Images Processing Workflow</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Implemented image capture, crop, compression, and serialization for message transfer.</li>
                </ul>
            </div>
        </div>
    );
}

const AtakPluginProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>SpaceLinq ATAK Plugin</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Miniaturized the entire satellite messaging application into a lightweight plugin that functioned within the architecture of the full military and consumer version of the Android Tactical Awareness Kit (ATAK) app.</li>
                </ul>
            </div>
        </div>
    );
}

const AndroidProjectsList = Object.freeze([
    { id: 'e2e', label: 'E2E Encryption in Satellite Messaging', content: <E2eProject /> },
    { id: 'images', label: 'Images Processing Workflow', content: <ImagesProject /> },
    { id: 'atak', label: 'ATAK PLUGIN', content: <AtakPluginProject /> }
]);

const SpaceLinqForWebProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>SpaceLinq for Web</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Web app to allow online users to communicate with satellite only users on any web enabled device.</li>
                </ul>
            </div>
        </div>
    );
}

const MCSxProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>Django Admin Console for Database Management</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Modernized database management for satellite channel and customer information by designing a Django-based admin page with an updated schema that reduced database size by over 80%.</li>
                    <li className="text-2xl">Deployed the admin web page on an Azure VM instance using Docker Compose, significantly enhancing data security and efficiency by reducing database exporting from an hours long manual job to an automated instant task.</li>
                    <li className="text-2xl">Collaborated with Sales and customers to add custom features to display output the the data to meet each unique need.</li>
                </ul>
            </div>
        </div>
    );
}

const WebProjectsList = Object.freeze([
    { id: 'Spacelinq for Web', label: 'Web to Satellite Messaging App', content: <SpaceLinqForWebProject /> },
    { id: 'MCSx', label: 'Django Admin Console', content: <MCSxProject /> }
]);

const AndroidProjects = () => {
    return (
        <section className="project-grouping">
            <Tabs items={AndroidProjectsList} color={'warning'}>
                {(item) => (
                    <Tab key={item.id} title={item.label} className="project-tabs">
                        {item.content}
                    </Tab>
                )}
            </Tabs>
        </section>
    );
};

const WebProjects = () => {
    return (
        <section className="project-grouping">
            <Tabs items={WebProjectsList} color={'warning'}>
                {(item) => (
                    <Tab key={item.id} title={item.label} className="project-tabs">
                        {item.content}
                    </Tab>
                )}
            </Tabs>
        </section>
    );
};

const TDLAPIProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>Tactical Data Link (TDL) API</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Linked HTTP Stream from Anduril's Lattice common operating picture (COP) to our satellite messaging system.</li>
                </ul>
            </div>
        </div>
    );
}

const TRAXMiddleWareProject = () => {
    return (
        <div className='project-wrapper'>
            <div className='my-4'>
                <h4>SpaceLinq TRAX Middleware</h4>
                <ul className="list-disc pl-8">
                    <li className="text-2xl">Authenticated and forwarded messages from Sierra Nevada Corporations TRAX application to our satellite messaging system.</li>
                </ul>
            </div>
        </div>
    );
}

const APIProjectsList = Object.freeze([
    { id: 'tdl', label: 'TDL API', content: <TDLAPIProject /> },
    { id: 'trax', label: 'TRAX Middleware', content: <TRAXMiddleWareProject /> },
]);

const APIProjects = () => {
    return (
        <section className="project-grouping">
            <Tabs items={APIProjectsList} color={'warning'}>
                {(item) => (
                    <Tab key={item.id} title={item.label} className="project-tabs">
                        {item.content}
                    </Tab>
                )}
            </Tabs>
        </section>
    );
};


function PortfolioPage() {
    return (
        <React.Fragment>
            <h4 style={{ textAlign: 'center' }}>**Work in progress...**</h4>
            <div className="portfolio-wrapper">
                <PortfolioHeader />
                <ProjectsContainer />
            </div>
        </React.Fragment>
    );
}

export default PortfolioPage;