import React from "react";
import "./Home.css"
import githubLogo from '../assets/images/icon-logo-github.png';
import linkedinLogo from '../assets/images/icon-logo-linkedin.png';
import avatar from '../assets/images/my-avatar.png';
import ThingsIThinkAreCool from "./CoolThings/ThingsIthinkAreCool";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";

const HomePageTitle = () => {
    // const clickRef = useRef(null);

    // useEffect(() => {
    //     const handleClick = () => {
    //         console.log('Clicked');
    //     };

    //     const currentRef = clickRef.current;
    //     if (currentRef) {
    //         currentRef.addEventListener('click', handleClick);
    //     }

    //     return () => {
    //         if (currentRef) {
    //             currentRef.removeEventListener('click', handleClick);
    //         }
    //     };
    // }, []);

    // return (<h1 ref={clickRef}>Jeffrey Bradley</h1>);
                
    const handleClick = (props) => {
        console.log(`Clicked ${props.name}`);
    };

    handleClick({name:"Jeff"});

    return (<h1 onClick={() => handleClick({name:"Jeff"})}>Jeffrey Bradley</h1>);

};

const AboutMeList = () => {
    const listAboutMe = ["Software Engineer", "Amateur Chef", "Musician", "Mountain Enthusiast"];
    return (
        <div id="about-me-list">
            {listAboutMe.map((item, index) => (
                <li className="tag-line" key={index}>{item}</li>
            ))}
        </div>
    );
};

const OldResumeButton = () => {
    return (
        <Link to="/resume">
            <nav className="resume-link" style={{ display: "inline-block" }}>
                Check Out My Resume (OLD)
            </nav>
        </Link>
    );
}

const ResumeButton = () => (
    <Link to="/portfolio">
        <nav className="resume-link" onClick={() => console.log("Clicked")} style={{ display: "inline-block" }}>
            Check Out My Resume *UNDER CONSTRUCTION*
        </nav>
    </Link>
);

// ResumeButton.addEventListener('mouseover', () => {
//     ResumeButton.style.boxShadow = '0 4px 15px rgba(0, 123, 255, 0.9)';
// });

// ResumeButton.addEventListener('mouseout', () => {
//     ResumeButton.style.boxShadow = 'none';
// });

const Header = () => {
    return (
        <div className="home-page-header">
            <HomePageTitle />
            <AboutMeList />
            <div>
                < OldResumeButton />
            </div>
            <div>
                <ResumeButton />
            </div>
        </div>
    )
}

const Summary = () => {
    return (
        <React.Fragment>
            <h2>
                Hello!
            </h2>
            <p>
                Welcome to my digital playground!
            </p>
            <p>
                If you are looking for more information on my professional career, check out my <a href="/portfolio" >resume</a>, but this space is dedicated to personal projects, learning experiences, and sharing my enthusiasm of technology.
            </p>
            <p>
                If you aren't looking for my resume, you are probably in the wrong place, or a hacker trying to infiltrate a site with no sensitive personal information hosted on an inexpensive sandboxed VM... for some reason.
            </p>
        </React.Fragment>
    )
}

const Main = () => {
    return (
        <main>
            <Summary />
        </main>
    )
}

const Footer = () => {
    return (
        <footer style={{ position: "fixed", bottom: 0, width:"100%" }}>
            <p>© 2024 by Jeffrey Bradley | <a href="mailto:jeffreybradley772@gmail.com">jeffreybradley772@gmail.com</a></p>
            <ul className="socials">
                <li>
                    <a href="https://github.com/JeffreyBradley772">
                        <img src={githubLogo} alt="My Github Profile" />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/jeffreybradley772/">
                        <img src={linkedinLogo} alt="My LinkedIn Profile" />
                    </a>
                </li>
            </ul>
        </footer>
    )
}

export default function Home() {
    return (
        <React.Fragment>
            <div className="content-wrapper">
                <Header />
                <Main />
                <ThingsIThinkAreCool />
            </div>
            <Footer />
        </React.Fragment>
    );
}

// function EventListenerExample() {
//     useEffect(() => {
//         const handleScroll = () => {
//             console.log('User is scrolling');
//         };

//         window.addEventListener('scroll', handleScroll);

//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     return <div>Scroll to see the event listener in action </div>;
// }